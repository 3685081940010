<template>
  <div class="wrapper">

<!--    <img class="header-logo" alt="Itbridge" src="../assets/about-background.svg">-->

    <div class="wrapper-discuss">
      <div class="content-text">
      <span class="text-main">
        We create and ensure uninterrupted operation of <span style="color: #0af886">IT infrastructure</span>
      </span>
        <span class="text-sub">
        Leader in building IT infrastructure, engineering solutions
        and security systems The Group has a strong position
        in IT outsourcing, business application implementation and IT consulting.
      </span>
        <button class="discuss-button"> Discuss the project</button>
      </div>
      <div class="content-img">
        <img class="img" alt="Itbridge" src="../assets/about-background1.png">
      </div>
    </div>
    <div class="achievements" id="achievements">

      <div class="achievement">
        <span class="text1"> 200+</span>
        <span class="text2"> Completed projects</span>
        <span> Innovation and quality in every project</span>
      </div>
      <div class="achievement">
        <span class="text1"> 50+</span>
        <span class="text2"> Professional employers</span>
        <span> A team you can rely on</span>
      </div>
      <div class="achievement">
        <span class="text1"> 15+</span>
        <span class="text2"> Successful work</span>
        <span> Time-tested experience</span>
      </div>

    </div>




  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped lang="scss">
@import './src/style/style.scss';

.wrapper {
  background-color: $base-color;
  padding: 30px var(--global-sides-margin);

}
.wrapper-discuss {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
.content-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  padding: 20px 0;
}
.text-main {
  font-size: 60px;
  font-weight: 500;
  line-height: 1.2;
  word-spacing: 1px;
  text-align: left;
  color: white;
}
.text-sub {
  text-align: left;
  margin-top: 24px;
  font-size: 20px;
  color: white;

}
.discuss-button {
  width: 50%;
  height: 80px;
  margin-top: 80px;
  border-radius: 40px;
  background: #0af886;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: 600;
}
.content-img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
}

.content-img img {
  width: 100%;
  object-fit: contain;
  //scale: 1.2;

}

.achievements {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-top: 100px;
}

.achievement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  padding: 30px;
  border-radius: 30px;
  width: 28%;
  background: white;
  margin-bottom: 48px;

  color: #110551;
  text-align: left;
  .text1 {
    font-weight: 800;
    font-size: 40px;
  }
  .text2 {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 12px;
  }

}

@media  screen and (max-width: 480px) {
  .achievements {
    flex-direction: column;
  }
  .content-img {
    display: none;
  }
  .text-main {
    font-size: 52px;
  }
  .content-text {
    width: 100%;
    align-items: center;
  }
  .discuss-button {
    width: 90%;
  }
  .achievement {
    width: auto;
  }
}
</style>
