<template>
  <div class="wrapper">

    <p class="services-header">Our services</p>
    <div class="services-wrapper">
      <ServiceCard
          v-for="(service, index) in services"
          :key="index"
          :image-path="service.imagePath"
          :text="service.text"
          :sub-text="service.subText"
      >
      </ServiceCard>
    </div>
  </div>

</template>

<script>
import ServiceCard from "./ServiceCard";
export default {
  name: "OurServices",
  components: {ServiceCard},
  data() {
    return {
      services: [
        {  imagePath: 'service1.png', text: 'Data processing and storage', subText: 'Data processing and storage are critical components of modern computing, allowing organizations to handle vast amounts of information efficiently.'},
        {  imagePath: 'service2.png', text: 'Office equipment and computers', subText: 'Office equipment and computers play a vital role in enhancing productivity in the workplace.'},
        {  imagePath: 'service3.png', text: 'Network design and creation', subText: 'Network design and creation are essential for establishing efficient and reliable communication systems within organizations.'},
        {  imagePath: 'service4.png', text: 'Infrastructure Solutions', subText: 'Infrastructure solutions provide the foundational support for IT services, enabling organizations to operate effectively and efficiently.'},
        {  imagePath: 'service5.png', text: 'Multimedia', subText: 'Multimedia integrates various forms of content, such as text, images, audio, and video, to create engaging and interactive experiences.'},
        {  imagePath: 'service6.png', text: 'IT Infrastructure Audit', subText: 'An IT Infrastructure Audit involves a comprehensive assessment of an organization\'s technology systems.'}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import './src/style/style.scss';
.services-header {
  text-align: left;
  font-size: 60px;
  font-weight: 700;
  color: #110551;

}
.wrapper {
  background-color: white;
  padding: 30px var(--global-sides-margin) 60px var(--global-sides-margin);
}
.services-wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}
@media  screen and (max-width: 480px) {
  .services-wrapper {
    grid-template-columns: 1fr;
    gap: 50px;

  }

}

</style>
