<template>
  <div class="wrapper-service-card">
    <div class="img-wrapper">
      <img :src="getImgUrl(imagePath)">
      <p style="font-size: 26px; font-weight: 500">{{text}}</p>
      <span style="text-align: left">
      {{subText}}
    </span>
    </div>


    <button class="button-service-card"> MORE</button>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    imagePath: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    subText: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    getImgUrl(pic) {
      // console.log('../assets/services/'+ pic)
      return require('../assets/services/'+ pic)
    }
  }
}
</script>

<style scoped>
.wrapper-service-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f8ff;
  padding: 20px;
  border-radius: 40px;
  color: #110551;

}
@media  screen and (max-width: 480px) {
  .wrapper-service-card {
    /*flex-direction: row;*/

  }
}

.img-wrapper {
  display: grid;
  padding: 0 60px;
}
.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.button-service-card {
  width: 100%;
  /*height: 20px;*/
  padding: 12px;
  margin-top: 30px;
  align-items: center;
  border-radius: 40px;
  background: #0af886;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: 600;
  align-self: flex-end;
}
</style>
