<template>
  <header>
    <div class="wrapper">
      <img @click="reloadPage" class="header-logo" alt="Itbridge" src="../assets/logo5.png">
      <div class="links">
        <a href="#achievements"> {{$t("about company")}}</a>
        <a href="#test2"> {{$t("our services")}}</a>
        <a href="#test3"> {{$t("contacts")}}</a>

        <div class="language-switch">

          <select class="language-select" v-model="$i18n.locale">
            <option value="en">Eng</option>
            <option value="rs">Srb</option>
          </select>
        </div>

      </div>
    </div>

    <span></span>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
    }
  },
  methods: {
    setLocale(locale) {
      console.log(locale)
      this.$i18n.locale = locale
    },
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>

<style scoped lang="scss">
@import './src/style/style.scss';
header {
  background: $base-color;
  //position: sticky;
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px var(--global-sides-margin);
}
.header-logo {
  width: 220px;
  cursor: pointer;
}
.links {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  a {
    font-size: 18px;
    color: white;
    text-decoration: none;
    height: fit-content;
  }
}
@media  screen and (max-width: 480px) {
  .links {
    display: none;
  }
}

.language-switch {
  border: none;
  background-color: transparent;
  margin-left: 60px;
}
.language-select {
  //--arrow-bg: rgba(255, 255, 255, 1);
  --arrow-bg: transparent;
  --option-bg: white;
  //--select-bg: rgba(255, 255, 255, 1);
  --select-bg: transparent;
  color: white;
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 60px;
  padding: 1rem 1rem 1rem 1rem;
  background:
  linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
  //color: black;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* <option> colors */
  option {
    color: inherit;
    background-color: var(--option-bg);
  }
}
</style>
