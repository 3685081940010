<template>

  <div class="wrapper">

    <div class="links">
      <span>About</span>
      <span>Features</span>
      <span>Blog</span>
      <span>About</span>
      <span>Contacts</span>
    </div>
    <img class="footer-logo" alt="Itbridge" src="../assets/logo10.png">

    <span class="rights-reserved"> © All Rights Reserved.</span>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">

@import './src/style/style.scss';

.wrapper {
  background-color: #110551;
  //height: 500px;
  padding: 30px var(--global-sides-margin) 60px var(--global-sides-margin);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.links {
  display: flex;
  flex-direction: row;
  color: white;
  gap: 26px;
}
.footer-logo {
  width: 200px;
  margin-top: 30px;
}
.rights-reserved {
  margin-top: 30px;
  color: rgba(255,255,255, 0.7);
}
</style>
