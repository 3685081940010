<template>
  <div id="app">
    <Header></Header>

    <About></About>
    <WhyUs></WhyUs>
    <OurServices></OurServices>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import About from "./components/About";
import WhyUs from "./components/WhyUs";
import OurServices from "./components/OurServices";
import Footer from "./components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    OurServices,
    WhyUs,
    About,
    Header,
  }
}
</script>

<style lang="scss">
@import './src/style/style.scss';

body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
